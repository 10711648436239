@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
      html {
        @apply bg-gray-950;
      }
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(assets/fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: "bold";
  src: local('Roboto'), url(assets/fonts/Roboto-Medium.ttf) format('truetype');
}

html {
	scroll-behavior: smooth;
}
@media screen and (prefers-reduced-motion: reduce) {
	html {
		scroll-behavior: auto;
	}
}

.background-gradient-animate {
    background-size: 300%;
    -webkit-animation: BgGradientAnimation 4s ease infinite;
    -moz-animation: BgGradientAnimation 4s ease infinite;
    animation: BgGradientAnimation 4s ease infinite;
}

@keyframes BgGradientAnimation {
    0% {
        background-position: 100 50%;
    }
    50% {
        background-position: 50% 0%;
    }
    100% {
        background-position: 100% 50%;
    }

}
